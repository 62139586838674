import { Path as NavigatePath, NavigateOptions } from 'react-router-dom';
import {
  ShareCommerce,
  ShareBroadcastCommerce,
} from '../../modules/commerce/v2/typings';
import { CustomFieldsTypes } from '../../modules/custom-fields/v1/typings';
import { OpenCustomerChatData, RetargetTemplateData } from '../../modules/customers/v1/typings';
import { TeamMemberContact } from '../../modules/team-management/v1/typings';
import { CurrentPath, DispatchSuccessModalProps, DispatchSuccessModalType, TemplateMessageData } from '../../shared/typings';
import { AnalyticsEventNames, AnalyticsEventOptions } from '../../shared/typings/analytics';
import { RefreshTypes } from './android';
import BasePlatform from './base';
import { WabaIntegrationMemberStatus } from '../../modules/waba-channels/v1/typings';
import { FrequencyCappingState } from '../../modules/channel-management/v1/typings';

enum IOSMessageTypes {
  LOGIN = 'LOGIN',
  BACK = 'BACK',
  LOGOUT = 'LOGOUT',
  NAVIGATION_DRAWER = 'NAVIGATION_DRAWER',
  UPLOAD_CUSTOMERS_FROM_PHONEBOOK = 'UPLOAD_CUSTOMERS_FROM_PHONEBOOK',
  SET_NAV_BAR_COLOR = 'SET_NAV_BAR_COLOR',
  SET_STATUS_BAR_COLOR = 'SET_STATUS_BAR_COLOR',
  ADD_GROUP_MEMBERS = 'ADD_GROUP_MEMBERS',
  OPEN_GROUP_ACITIVITY = 'OPEN_GROUP_ACITIVITY',
  MESSAGE_GROUP_MEMBER = 'MESSAGE_GROUP_MEMBER',
  SHARE_COMMERCE = 'SHARE_COMMERCE',
  FINISH_ACTIVITY = 'FINISH_ACTIVITY',
  SELECT_TEMPLATE_FROM_LIST = 'SELECT_TEMPLATE_FROM_LIST',
  INVITE_CONTACT = 'INVITE_CONTACT',
  OPEN_CUSTOMER_DETAILS = 'OPEN_CUSTOMER_DETAILS',
  OPEN_CUSTOMER_CHAT = 'OPEN_CUSTOMER_CHAT',
  RELOAD_PAGE_DATA = 'RELOAD_PAGE_DATA',
  TEAM_CONTACT_ADDED = 'TEAM_CONTACT_ADDED',
  TEMPLATE_DATA_SEND = 'TEMPLATE_DATA_SEND',
  REFRESH_CUSTOMERS = 'REFRESH_CUSTOMERS',
  SEND_DATA_DEBUG = 'SEND_DATA_DEBUG',
  OPEN_LINK = 'OPEN_LINK',
  OPEN_INTEGRATIONS = 'OPEN_INTEGRATIONS',
  COPY_DATA_TO_CLIPBOARD = 'COPY_DATA_TO_CLIPBOARD',
  SHARE_DATA = 'SHARE_DATA',
  FINISH_ACTIVITY_OK = 'FINISH_ACTIVITY_OK',
  SET_USER_STATUS = 'SET_USER_STATUS',
  CHANGE_CUSTOM_TOKEN = 'CHANGE_CUSTOM_TOKEN',
  SWITCH_ORG = 'SWITCH_ORG',
  OPEN_PRICING_PLANS = 'OPEN_PRICING_PLANS',
  RETARGET_TEMPLATE = 'RETARGET_TEMPLATE',
}

type IOSLoginRequestData = {
  messageType: IOSMessageTypes.LOGIN;
  messageParams: {};
};

type IOSViewPricingPlans = {
  messageType: IOSMessageTypes.OPEN_PRICING_PLANS;
  messageParams: {};
};

type IOSBackRequestData = {
  messageType: IOSMessageTypes.BACK;
  messageParams: {};
};

type IOSInviteContact = {
  messageType: IOSMessageTypes.INVITE_CONTACT;
  messageParams: {};
};

type IOSOpenLink = {
  messageType: IOSMessageTypes.OPEN_LINK;
  messageParams: {
    link: string;
  };
};

type IOSCopyToClipboard = {
  messageType: IOSMessageTypes.COPY_DATA_TO_CLIPBOARD;
  messageParams: {
    data: any;
  };
};

type IOSShareData = {
  messageType: IOSMessageTypes.SHARE_DATA;
  messageParams: {
    data: string;
  };
};

type IOSRetargetTemplate = {
  messageType: IOSMessageTypes.RETARGET_TEMPLATE;
  messageParams: {
    data: RetargetTemplateData;
  };
};

type IOSOpenCustomerDetails = {
  messageType: IOSMessageTypes.OPEN_CUSTOMER_DETAILS;
  messageParams: {
    customerName: string;
    customerPhoneNumber: string;
  };
};
type IOSOpenCustomerChat = {
  messageType: IOSMessageTypes.OPEN_CUSTOMER_CHAT;
  messageParams: {
    data: OpenCustomerChatData;
  };
};

type IOSLogoutRequestData = {
  messageType: IOSMessageTypes.LOGOUT;
  messageParams: {};
};

type IOSSetStatusBarColorRequestData = {
  messageType: IOSMessageTypes.SET_STATUS_BAR_COLOR;
  messageParams: { color: string };
};

type IOSSetNavigationBarColorRequestData = {
  messageType: IOSMessageTypes.SET_NAV_BAR_COLOR;
  messageParams: { color: string };
};

type IOSNavigationRequestData = {
  messageType: IOSMessageTypes.NAVIGATION_DRAWER;
  messageParams: {};
};

type IOSFinishActivity = {
  messageType: IOSMessageTypes.FINISH_ACTIVITY;
  messageParams: {};
};

type IOSSelectTemplateFromList = {
  messageType: IOSMessageTypes.SELECT_TEMPLATE_FROM_LIST;
  messageParams: {};
};

type IOSOpenIntegrations = {
  messageType: IOSMessageTypes.OPEN_INTEGRATIONS;
  messageParams: {};
};

type IOSUploadCustomersFromPhoneBook = {
  messageType: IOSMessageTypes.UPLOAD_CUSTOMERS_FROM_PHONEBOOK;
  messageParams: {};
};

type IOSAddGroupMembersRequestData = {
  messageType: IOSMessageTypes.ADD_GROUP_MEMBERS;
  messageParams: {
    groupId: string;
    groupChatId: string;
    groupChatName: string;
  };
};

type IOSMessageGroupMemberRequestData = {
  messageType: IOSMessageTypes.MESSAGE_GROUP_MEMBER;
  messageParams: {
    customerId: string;
    customerName: string;
    chatId?: string;
    phoneNumber?: string;
    integrationId?: string;
    integrationWabaNumber?: string;
  };
};

type IOSOpenGroupActivityRequestData = {
  messageType: IOSMessageTypes.OPEN_GROUP_ACITIVITY;
  messageParams: {
    groupChatId: string;
    groupId: string;
    groupChatName: string;
  };
};

type IOSShareCommerce = {
  messageType: IOSMessageTypes.SHARE_COMMERCE;
  messageParams: {
    data: ShareCommerce;
  };
};
type IOSSendDataDebug = {
  messageType: IOSMessageTypes.SEND_DATA_DEBUG;
  messageParams: any;
};

type IOSFinishActivityWithResultOk = {
  messageType: IOSMessageTypes.FINISH_ACTIVITY_OK;
  messageParams: any;
};

type IOSSetUserStatusRequestData = {
  messageType: IOSMessageTypes.SET_USER_STATUS;
  messageParams: any;
}

type IOSChangeCustomToken = {
  messageType: IOSMessageTypes.CHANGE_CUSTOM_TOKEN;
  messageParams: {
    token: string;
  };
};

type IOSSwitchOrganisation = {
  messageType: IOSMessageTypes.SWITCH_ORG;
  messageParams: {
    orgId: string;
  };
};

type IOSRequestData =
  | IOSLoginRequestData
  | IOSBackRequestData
  | IOSLogoutRequestData
  | IOSNavigationRequestData
  | IOSSetStatusBarColorRequestData
  | IOSSetNavigationBarColorRequestData
  | IOSAddGroupMembersRequestData
  | IOSMessageGroupMemberRequestData
  | IOSOpenGroupActivityRequestData
  | IOSShareCommerce
  | IOSFinishActivity
  | IOSSelectTemplateFromList
  | IOSInviteContact
  | IOSOpenCustomerDetails
  | IOSOpenCustomerChat
  | IOSSendDataDebug
  | IOSOpenLink
  | IOSCopyToClipboard
  | IOSShareData
  | IOSOpenIntegrations
  | IOSUploadCustomersFromPhoneBook
  | IOSFinishActivityWithResultOk
  | IOSSetUserStatusRequestData
  | IOSChangeCustomToken
  | IOSRetargetTemplate
  | IOSSwitchOrganisation
  | IOSViewPricingPlans;

declare global {
  interface Window {
    webkit: {
      messageHandlers: {
        iOS: {
          postMessage: (params: IOSRequestData) => void;
        };
      };
    };
    onMessageFromIOS: (receivedMessage?: unknown) => void;
  }
}

type IOSLoginRequestCache = {
  login?: {
    cachedPromiseResolve: (value: string | PromiseLike<string>) => void;
  };
};

type IOSRequestCache = IOSLoginRequestCache;

type IOSLoginResult = {
  messageType: IOSMessageTypes.LOGIN;
  messageData: {
    token: string;
  };
};

type IOSReloadPageDataResult = {
  messageType: IOSMessageTypes.RELOAD_PAGE_DATA;
  messageData: {
    refreshType: RefreshTypes;
  };
};

type IOSTeamContactAdded = {
  messageType: IOSMessageTypes.TEAM_CONTACT_ADDED;
  messageData: TeamMemberContact;
};

type IOSTemplateDataSend = {
  messageType: IOSMessageTypes.TEMPLATE_DATA_SEND;
  messageData: TemplateMessageData;
};

type IOSCustomerDataChange = {
  messageType: IOSMessageTypes.REFRESH_CUSTOMERS;
  messageData: {
    customerName: string;
    customerId: string;
  };
};

type IOSSetUserStatusResult = {
  messageType: IOSMessageTypes.SET_USER_STATUS;
  messageData: {
    status: WabaIntegrationMemberStatus | null;
    statusExpiration: string | null;
  };
};

type IOSResult =
  | IOSLoginResult
  | IOSReloadPageDataResult
  | IOSTeamContactAdded
  | IOSTemplateDataSend
  | IOSCustomerDataChange
  | IOSSetUserStatusResult;

export default class IOS extends BasePlatform {
  cachedPromise: IOSRequestCache = {};

  isCurrentPlatform(): boolean {
    if (
      typeof window === 'undefined' ||
      typeof window.webkit === 'undefined' ||
      typeof window.webkit.messageHandlers === 'undefined' ||
      typeof window.webkit.messageHandlers.iOS === 'undefined' ||
      typeof window.webkit.messageHandlers.iOS.postMessage !== 'function'
    ) {
      return false;
    }
    this.listenForMessageFromIos();
    return true;
  }

  private handleMessageType({ messageType, messageData }: IOSResult) {
    switch (messageType) {
      case IOSMessageTypes.LOGIN:
        this.cachedPromise.login?.cachedPromiseResolve(messageData.token);
        delete this.cachedPromise.login;
        break;
      case IOSMessageTypes.RELOAD_PAGE_DATA: {
        const customEvent = new CustomEvent('reloadpagedata', {
          detail: { refreshType: messageData.refreshType },
        });
        this.sendDataDebug({ messageType, messageData });
        document.dispatchEvent(customEvent);
        break;
      }
      case IOSMessageTypes.TEAM_CONTACT_ADDED: {
        const customEvent = new CustomEvent('teamContactAdded', {
          detail: messageData,
        });
        this.sendDataDebug({ messageType, messageData });
        document.dispatchEvent(customEvent);
        break;
      }
      case IOSMessageTypes.TEMPLATE_DATA_SEND: {
        const customEvent = new CustomEvent('templateDataSend', {
          detail: messageData.messageData,
        });
        this.sendDataDebug({ messageType, messageData });
        document.dispatchEvent(customEvent);
        break;
      }
      case IOSMessageTypes.REFRESH_CUSTOMERS: {
        const customEvent = new CustomEvent('refreshCustomers', {
          detail: messageData,
        });
        this.sendDataDebug({ messageType, messageData });
        document.dispatchEvent(customEvent);
        break;
      }
      case IOSMessageTypes.SET_USER_STATUS: {
        const customEvent = new CustomEvent('setUserStatus', {
          detail: messageData,
        });
        this.sendDataDebug({ messageType, messageData });
        document.dispatchEvent(customEvent);
        break;
      }
      default: {
        return;
      }
    }
  }

  private listenForMessageFromIos() {
    window.onMessageFromIOS = (receivedMessage) => {
      if (!receivedMessage || typeof receivedMessage !== 'string') {
        return;
      }
      this.handleMessageType(JSON.parse(receivedMessage));
    };
  }

  hasMethod(functionName: string): boolean {
    return false;
  }

  getToken(): Promise<string> {
    return new Promise((resolve) => {
      this.cachedPromise.login = { cachedPromiseResolve: resolve };
      window.webkit.messageHandlers.iOS.postMessage({
        messageType: IOSMessageTypes.LOGIN,
        messageParams: {},
      } as IOSRequestData);
    });
  }

  goBack(): void {
    window.webkit.messageHandlers.iOS.postMessage({
      messageType: IOSMessageTypes.BACK,
      messageParams: {},
    } as IOSRequestData);
  }
  openRenewalBlocker(): void {
    // todo: use this to open renewal blocker natively
  }
  
  finishActivity(): void {
    window.webkit.messageHandlers.iOS.postMessage({
      messageType: IOSMessageTypes.FINISH_ACTIVITY,
      messageParams: {},
    } as IOSRequestData);
  }
  
  getOrigin() {
    // window?.parent?.postMessage?.({ type: 'ASK_ORIGIN' }, '*', []);
  }
  
  getUtmSource(): string {
    return 'ios_app'
  }

  logout(): void {
    window.webkit.messageHandlers.iOS.postMessage({
      messageType: IOSMessageTypes.LOGOUT,
      messageParams: {},
    } as IOSRequestData);
  }

  openPricingPlans(): void {
    window.webkit.messageHandlers.iOS.postMessage({
      messageType: IOSMessageTypes.OPEN_PRICING_PLANS,
      messageParams: {},
    } as IOSRequestData);
  }

  openNavigationDrawer(): void {
    window.webkit.messageHandlers.iOS.postMessage({
      messageType: IOSMessageTypes.NAVIGATION_DRAWER,
      messageParams: {},
    } as IOSRequestData);
  }

  setStatusBarColor(color: string = '#ffffff'): void {
    window.webkit.messageHandlers.iOS.postMessage({
      messageType: IOSMessageTypes.SET_STATUS_BAR_COLOR,
      messageParams: { color },
    } as IOSRequestData);
  }

  setNavigationBarColor(color: string = '#ffffff'): void {
    window.webkit.messageHandlers.iOS.postMessage({
      messageType: IOSMessageTypes.SET_NAV_BAR_COLOR,
      messageParams: { color },
    } as IOSRequestData);
  }

  getVersion(): number {
    return 0;
  }

  addGroupMembers(
    groupId: string,
    groupChatId: string,
    groupChatName: string
  ): void {
    window.webkit.messageHandlers.iOS.postMessage({
      messageType: IOSMessageTypes.ADD_GROUP_MEMBERS,
      messageParams: { groupId, groupChatId, groupChatName },
    } as IOSRequestData);
  }

  messageGroupMember(data: {
    customerId: string;
    customerName: string;
    chatId?: string;
    phoneNumber?: string;
    integrationId?: string;
    integrationWabaNumber?: string;
  }): void {
    window.webkit.messageHandlers.iOS.postMessage({
      messageType: IOSMessageTypes.MESSAGE_GROUP_MEMBER,
      messageParams: data,
    } as IOSRequestData);
  }

  openGroupChat(
    groupChatId: string,
    groupId: string,
    groupChatName: string
  ): void {
    window.webkit.messageHandlers.iOS.postMessage({
      messageType: IOSMessageTypes.OPEN_GROUP_ACITIVITY,
      messageParams: { groupChatId, groupId, groupChatName },
    } as IOSRequestData);
  }

  shareCommerce(data: ShareCommerce): void {
    window.webkit.messageHandlers.iOS.postMessage({
      messageType: IOSMessageTypes.SHARE_COMMERCE,
      messageParams: { data },
    } as IOSRequestData);
  }

  shareBroadcastCommerce(data: ShareBroadcastCommerce): void {}

  inviteContact(): void {
    window.webkit.messageHandlers.iOS.postMessage({
      messageType: IOSMessageTypes.INVITE_CONTACT,
      messageParams: {},
    } as IOSRequestData);
  }

  selectTemplateFromList(selectedWabaPhoneNumber: string): void {
    window.webkit.messageHandlers.iOS.postMessage({
      messageType: IOSMessageTypes.SELECT_TEMPLATE_FROM_LIST,
      messageParams: {},
    } as IOSRequestData);
  }

  openCustomerDetailActivity(
    customerName: string,
    customerPhoneNumber: string
  ): void {
    window.webkit.messageHandlers.iOS.postMessage({
      messageType: IOSMessageTypes.OPEN_CUSTOMER_DETAILS,
      messageParams: {
        customerName,
        customerPhoneNumber,
      },
    } as IOSRequestData);
  }

  openDeepLink(url: string): void {}

  openLink(url: string): void {
    window.webkit.messageHandlers.iOS.postMessage({
      messageType: IOSMessageTypes.OPEN_LINK,
      messageParams: { link: url },
    } as IOSRequestData);
  }

  trackEvent(eventName: AnalyticsEventNames, properties?:  AnalyticsEventOptions): void {}

  finishActivityWithError(message: string): void {}

  openCustomerChat(data: OpenCustomerChatData): void {
    window.webkit.messageHandlers.iOS.postMessage({
      messageType: IOSMessageTypes.OPEN_CUSTOMER_CHAT,
      messageParams: { data },
    } as IOSRequestData);
  }

  openIntegrations(): void {
    window.webkit.messageHandlers.iOS.postMessage({
      messageType: IOSMessageTypes.OPEN_INTEGRATIONS,
      messageParams: {},
    } as IOSRequestData);
  }
  uploadCustomersFromPhonebook(): void {
    window.webkit.messageHandlers.iOS.postMessage({
      messageType: IOSMessageTypes.UPLOAD_CUSTOMERS_FROM_PHONEBOOK,
      messageParams: {},
    } as IOSRequestData);
  }

  sendDataDebug(data: any): void {
    window.webkit.messageHandlers.iOS.postMessage({
      messageType: IOSMessageTypes.SEND_DATA_DEBUG,
      messageParams: { data },
    } as IOSRequestData);
  }

  redirect(url: string,state?: any): void {}

  setRoutePath(path: Partial<NavigatePath>, options?: NavigateOptions): void {}

  copyToClipboard(data: any): Promise<any> {
    window.webkit.messageHandlers.iOS.postMessage({
      messageType: IOSMessageTypes.COPY_DATA_TO_CLIPBOARD,
      messageParams: { data },
    } as IOSRequestData);
    return Promise.resolve('done');
  }

  shareData(data: string) {
    window.webkit.messageHandlers.iOS.postMessage({
      messageType: IOSMessageTypes.SHARE_DATA,
      messageParams: { data },
    } as IOSRequestData);
  }

  finishActivityWithResultOk(): void {
    window.webkit.messageHandlers.iOS.postMessage({
      messageType: IOSMessageTypes.FINISH_ACTIVITY_OK,
      messageParams: {},
    } as IOSRequestData);
  }

  createCustomField(customFieldId: string, type: CustomFieldsTypes): void {}

  updateCustomField(customFieldId: string, type: CustomFieldsTypes): void {}

  deleteCustomField(customFieldId: string, type: CustomFieldsTypes): void {}

  retargetTemplate(data: RetargetTemplateData): void {
    
    const action = data.action.split('::')[0];
    const buttonText = data.action.split('::')[1];

    const { chatId, groupId, broadcastId } = data;
    window.webkit.messageHandlers.iOS.postMessage({
      messageType: IOSMessageTypes.RETARGET_TEMPLATE,
      messageParams: {data: {
        action,
        chatId,
        groupId,
        broadcastId,
        ...(buttonText ? { buttonText } : {}),
      }},
    } as IOSRequestData);
  }

  getTemplateData(id: string): void {}

  sendCurlForTemplateAsApi(curl: string): void {}

  onAddToBroadcastList(): void {}

  getSelectedCustomerIds(): void {}

  changeCustomToken(token: string): void {
    window.webkit.messageHandlers.iOS.postMessage({
      messageType: IOSMessageTypes.CHANGE_CUSTOM_TOKEN,
      messageParams: { token },
    } as IOSRequestData);
  }

  async getCurrentPath(iframeId: string): Promise<CurrentPath> {
    return Promise.resolve({
      href: window.location.href,
      pathname: window.location.pathname,
      search: window.location.search,
      hash: window.location.hash,
      host: window.location.host,
      protocol: window.location.protocol,
    });
  }

  async dialog360Connect(): Promise<{
    client: string;
    channels: string[];
    revokedChannels?: string[];
  }> {
    return Promise.resolve({
      client: '',
      channels: [],
      revokedChannels: undefined,
    });
  }

  openBotBuilderModal(botId: string): void {}

  closeBotBuilderModal(): void {}

  openImportExcelModal(): void {}

  openAutoRetrySettingsModal(): void {}

  setAutoRetry(broadcastId: string,state: FrequencyCappingState): void {}

  sendChannelMembersInfo(): void {}

  switchOrganisation(orgId: string): void {
    window.webkit.messageHandlers.iOS.postMessage({
      messageType: IOSMessageTypes.SWITCH_ORG,
      messageParams: { orgId },
    } as IOSRequestData);
  };

  setUserStatus(status: WabaIntegrationMemberStatus | null, statusExpiration: string | null): void {
    window.webkit.messageHandlers.iOS.postMessage({
      messageType: IOSMessageTypes.SET_USER_STATUS,
      messageParams: { status, statusExpiration },
    } as IOSRequestData);
  }

  openDispatchSuccessModal(type: DispatchSuccessModalType, data: DispatchSuccessModalProps): void {}
}
